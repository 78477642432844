<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-6"><h4 class="smtp-out-message"> Number of contacts unsubscribed from this list: <span class="text-orange"><b>1</b></span></h4></div>
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Unsubscriber List <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <!--<th class="no-sort"  name="name" width="30"></th>-->
                                <th class="text-capitalize sorting">
                                    ID
                                </th>
                                <th class="text-capitalize sorting">
                                    Name
                                </th>
                                <th class="text-capitalize sorting">
                                   Email
                                </th>
                                 <th class="text-capitalize sorting">
                                   Company 
                                </th>
                                 <th class="text-capitalize sorting">
                                   Phone
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2">
                               
                                <td>
                                	2
                                </td>
                                <td>
                                   <a href="">Jhon Doe</a>
                                </td>
                                <td>
                                  <a href=""> jhon.doe@example.com</a>
                                </td>
                                <td>
                                	Example Inc.
                                </td>
                                <td>
                                   (work) 2300-000000
                                </td>
                              
                            </tr>
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
   

</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewStockResponsePropertyForm from '../components/NewStockResponsePropertyForm.vue';
import StockResponseDetail from '../components/StockResponseDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'StockResponse Settings',
   components: {
    iServiceSwitch,
    Multiselect,
    NewStockResponsePropertyForm,
    Toggle,
    Multiselect,
    StockResponseDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showStockResponseDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
