<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-6">
                    <h4 class="smtp-out-message"> Number of contacts subscribed to this
                        list : <span class=" text-orange"><b>1234</b></span></h4>
                </div>
                <div class="col-md-6 text-end">
                    <div class="form-group" v-if="!showDetails.contactsearch" style="">
                        <button class="btn btn-primary" type="button" @click="showCotactSearch();">
                            Add Contacts
                        </button>
                        <button class="btn btn-primary" type="button" @click="removeContact();">
                            Remove Contacts
                        </button>
                    </div>

                    <div class="form-group" v-if="showDetails.contactsearch">
                        <button class="btn btn-primary" type="button" @click="hideContactSearch();">
                            Back To Current Contacts
                        </button>
                    </div>
                </div>
                <div class="col-md-12" v-if="!showDetails.contactsearch">
                    <div class="box box-info">
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button  class="btn btn-default" type="button" ><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Subscribers List <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <!--<th class="no-sort"  name="name" width="30"></th>-->
                                <th class="text-capitalize sorting">
                                    ID
                                </th>
                                <th class="text-capitalize sorting">
                                    Name
                                </th>
                                <th class="text-capitalize sorting">
                                   Email
                                </th>
                                 <th class="text-capitalize sorting">
                                   Company 
                                </th>
                                 <th class="text-capitalize sorting">
                                   Phone
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2">
                               
                                <td>
                                	2
                                </td>
                                <td>
                                  <a href=""> Jhon Doe</a>
                                </td>
                                <td>
                                   <a href="">jhon.doe@example.com</a>
                                </td>
                                <td>
                                	Example Inc.
                                </td>
                                <td>
                                   (work) 2300-000000
                                </td>
                              
                            </tr>
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                
                <div class="col-md-12" v-if="showDetails.contactsearch">
                    <div class="box box-primary">
                        <div class="box-header with-border">
                            <i class="fa fa-search"></i>
                            <h3 class="box-title"> Search For Contacts To Add To This Mailing List </h3>
                            <!--v-if-->
                        </div>
                        <div class="box-body">
                            <section class="search-parameters">
                                <fieldset id="Main_Subscribers_Group0">
                                <div class="panel panel-default col-12 bg-gray-light">
                                    <div class="row" id="Main_Subscribers_Group0_Field0">
                                        <div class="col-12 col-md-2" id="Main_Subscribers_Group0_Field0_Where">
                                            <div class="floating-label bg-white">
                                            <select>
                                                <option>anywhere</option>
                                                <option>start</option>
                                                <option>end</option>
                                                <option>entire</option>
                                            </select>
                                            <label>Search where</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4" id="Main_Subscribers_Group0_Field0_Field">
                                            <div class="floating-label bg-white">
                                            <select>
                                                <option value="all">-- All --</option>
                                                <option value="ID">-- ID --</option>
                                                <option value="login">-- Login --</option>
                                                <option value="loginbounced">-- Login (Bounced) --</option>
                                                <option value="segment">-- Segment Membership --</option>
                                                <option value="48">(Global) Address1</option>
                                                <option value="49">(Global) Address2</option>
                                                <option value="50">(Global) City</option>
                                                <option value="7">(Global) Company</option>
                                                <option value="53">(Global) Country</option>
                                                <option value="6">(Global) Customer Type</option>
                                                <option value="1">(Global) First Name</option>
                                                <option value="8">(Global) Job Title</option>
                                                <option value="3">(Global) Last Name</option>
                                                <option value="2">(Global) Middle Initial</option>
                                                <option value="4">(Global) Phone</option>
                                                <option value="52">(Global) Postal Code</option>
                                                <option value="51">(Global) State</option>
                                                <option value="112">(_iService) Business Model</option>
                                                <option value="57">(_iService) Buyer Type</option>
                                                <option value="47">(_iService) Campaign</option>
                                                <option value="79">(_iService) Campaign 2</option>
                                                <option value="95">(_iService) CampaignOrig</option>
                                                <option value="27">(_iService) Close Reason</option>
                                                <option value="34">(_iService) Company Employees</option>
                                                <option value="33">(_iService) Company Revenue</option>
                                                <option value="26">(_iService) Contact Source</option>
                                                <option value="58">(_iService) Current CRM</option>
                                                <option value="109">(_iService) Department</option>
                                                <option value="28">(_iService) Follow Up</option>
                                                <option value="32">(_iService) Industry</option>
                                                <option value="60">(_iService) Interaction Mgmt Solution</option>
                                                <option value="107">(_iService) Job Function</option>
                                                <option value="84">(_iService) Lead Scoring</option>
                                                <option value="85">(_iService) Lead Scoring Notes</option>
                                                <option value="80">(_iService) LinkedIn</option>
                                                <option value="108">(_iService) Management Level</option>
                                                <option value="87">(_iService) NAICS</option>
                                                <option value="113">(_iService) Never Bounce Email</option>
                                                <option value="56">(_iService) Next Call Date</option>
                                                <option value="54">(_iService) Referred by</option>
                                                <option value="81">(_iService) SIC</option>
                                                <option value="64">(_iService) Special Notes</option>
                                                <option value="90">(_iService) Stock Symbol</option>
                                                <option value="110">(_iService) Subindustry</option>
                                                <option value="67">(_iService) Time Zone</option>
                                                <option value="106">(_iService) Website</option>
                                                <option value="111">(_iService) Zoominfo URL</option>
                                                <option value="15">(1to1service) Account Number</option>
                                                <option value="16">(1to1service) Web Site Type</option>
                                                <option value="114">(Brian Adams Sales) campaign stage</option>
                                                <option value="100">(Brian Adams Sales) Follow Up Date</option>
                                                <option value="76">(Scott Sales) Buying Window</option>
                                                <option value="44">(Scott Sales) Opportunity Amount</option>
                                                <option value="43">(Scott Sales) Opportunity Description</option>
                                                <option value="42">(Scott Sales) Opportunity Name</option>
                                                <option value="45">(Scott Sales) Opportunity Probability</option>
                                                <option value="25">(Scott Sales) Opportunity Stage</option>
                                                <option value="46">(Scott Sales) Product</option>
                                                <option value="29">(Scott Sales) Sales Person</option>
                                                <option value="41">(Scott Sales) Target Close Date</option>
                                                <option value="98">(Scott Sales) Top Prospects</option>
                                            </select>
                                            <label>In field</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 search-field">
                                            <div class="floating-label bg-white"><input id="Main_Subscribers_Group0_Field0_Text"><label>Search for</label></div>
                                            <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                        </div>
                                        <button type="button" class="btn btn-link hidden-xs hidden-sm" style="text-align: left; display: none;">Or</button>
                                    </div>
                                </div>
                                <div style="display: none;"><button type="button" class="btn btn-link">And</button></div>
                                </fieldset>
                                <div class="row"><button class="btn btn-lg btn-link" type="button"><i class="fa fa-plus-circle text-light-blue"></i> Add another search parameter </button></div>
                            </section>
                            <footer class="box-footer text-right">
                                <button type="submit" class="btn btn-primary btn-sm">
                                Search <i class="fa fa-fw fa-arrow-circle-right"></i><!--v-if-->
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
    

</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewStockResponsePropertyForm from '../components/NewStockResponsePropertyForm.vue';
import StockResponseDetail from '../components/StockResponseDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'StockResponse Settings',
   components: {
    iServiceSwitch,
    Multiselect,
    NewStockResponsePropertyForm,
    Toggle,
    Multiselect,
    StockResponseDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showStockResponseDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';
       var contactsearch = reactive(false); 
       var subscribe = true; 
       var unsubscribe = false;

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showStockResponseDetails=false;
       }
       
       function showCotactSearch(){
          showDetails.contactsearch = true; showDetails.subscribe = false; showDetails.unsubscribe = true;
       }
       
       function hideContactSearch(){
        showDetails.contactsearch = false; showDetails.subscribe = false; showDetails.unsubscribe = true;
       } 

       function removeContact(){
         showDetails.contactsearch = false; showDetails.subscribe = false; showDetails.unsubscribe = true;
       }

       return{contactsearch,subscribe,unsubscribe, showCotactSearch,hideContactSearch,removeContact, cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
